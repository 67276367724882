.App {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

a {
  text-decoration: none; /* no underline */
}

.footer-container {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 20px;
  text-align: center;
}

/* Brukes ikke */
.href-tekst {
  color: #FFFFFF;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}


.error {
  color: #FFFFFF;
  text-align: center;
}

.example::-webkit-scrollbar {
  display: none;
}

.full_container {
  width: 80%;
  margin-top: 10px;
  height: 100%;
}

.linked_in_icon {
  width: 50px;
  height: 50px;
  background-size: cover;
}

.linked_in_icon:hover {
  opacity: 0.8;
}

.linked_in_right {
  display: flex;
  justify-content: flex-end;
}

.back_btn_left {
  display: flex;
  justify-content: flex-start;
}

.back_btn_icon {
  background-image: url(../assets/back_arrow.svg);
  background-size: cover;
  height: 60px;
  width: 60px;
}

.back_btn_icon:hover {
  opacity: 0.8;
}

.bakgrunn_image {
  padding-top: 270px;
  padding-left: 5px;
  padding-right: 5px;
  background-image: url(../assets/fjell_finish.svg);
  background-position: 50% 0%;
  width: 100%;
  background-size: cover;
  background-repeat: repeat-y;
  object-fit: scale-down;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 10px;
}


.image {
  background-image: url(../assets/profil_jakob_kvadrat.jpg);
  background-position:50% 50%;
  background-size:contain;
  width: 200px;
  height: 200px;
  max-height: 200px;
  max-width: 200px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50%;
  object-fit: cover;
  opacity: 1;
}

.image:tiny {
  width: 125px;
  height: 125px;
}

.intro-wrap {
  margin-top: 25px;
  text-align: center;
}

.name-text {
  letter-spacing: 2px;
  word-spacing: 3px;
  font-size: 30px;
  line-height: 34px;
  font-weight:bold;
  text-align: center;
  color: WHITE;
}

.project-title {
  letter-spacing: 2px;
  word-spacing: 3px;
  font-size: 30px;
  line-height: 34px;
  font-weight:bold;
  text-align: center;
  color: #6A6A6A;
  margin-bottom: 40px;
  text-decoration: underline;; /* no underline */
}

.paragraph-light-forside.paragraph-light-forside {
  text-align: center;
  color: WHITE;
}

.paragraph-light-forside-mail {
  font-weight:normal;
  font-size: 16px;
  text-align: center;
  color: WHITE;
}

.tekst-tittel {
  font-weight:bold;
  color: #6A6A6A;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.tekst-avsnitt {
  color: #6A6A6A;
  font-size: 16px;
  margin-bottom: 10px;
}

.tekst-hashtag {
  color: #6A6A6A;
  font-size: 13px;
  font-weight: bolder;
  margin-bottom: 10px;
  word-spacing: 5px;
}

.innhold-container {
  padding: 10px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.innhold-container-prosjekt {
  padding: 10px;
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.prosjekt-container {
  padding: 10px;
  height: 100%;
  min-height: 200px;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: White;
  border-radius: 1px;
  box-shadow: 0px 1px 4px 0.5px #DCDCDC;
  flex: 2 0 21%;
  align-items: center;
}

.prosjekt-container:hover {
  background-color: #F5F5F5;
  box-shadow: 0px 1px 4px 1px #DCDCDC;
  border-radius: 2px;
}


.prosjekt-container-simple {
  width: 90%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  background-color: White;
  flex: 2 0 21%;
  border: 1px #DCDCDC solid;
  align-items: center;
  padding: 10px;
}

.prosjekt-container-image {
  width: 30%;
  border-radius: 1px 0px 0px 0px;
  height: auto;
  max-height: 300px;
  overflow: hidden;
}

.prosjekt-image-full {
  width: 90%;
  overflow: hidden;
  height: auto;
  background-size: cover;
  object-fit:scale-down;
  background-position:50% 50%;
}


.prosjekt-container-tekstboks {
  padding: 20px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 2 0 21%;
    color: #6A6A6A;
}

.prosjekt-tekstboks-full {
  padding: 10px;
  width: 90%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex: 2 0 21%;
  color: #6A6A6A;
}


.divider-line {
  width: 80%;
  height: 1px;
  border: 1px #DCDCDC solid;
  margin: 30px 0px 30px 0px;
}

.divider-blank {
  margin: 20px 0px 20px 0px;
}

.knapp {
  height: 40px;
  font-size: 15px;
  width: 50%;
  max-width: 600px;
  border: 0px #DCDCDC solid;
  border-radius: 10px;
  font-weight: bold;
  color: #6A6A6A;
  margin-top: 20px;
  padding: 5px;
    box-shadow: 0px 1px 2px 0.5px #DCDCDC;
}

.knapp:hover{
  opacity: 0.4;
  background-color: #FF9900;
  color: #FFFFFF;
}

@media screen and (max-width: 1200px) {
  .bakgrunn_image {
    padding-top: 200px;
  }

  .prosjekt-container-simple {
    flex-direction: column-reverse;
    width: 90%;
    border-radius: 5px 5px 0px 0px;
  }

  .prosjekt-container-image {
    width: 100%;
    object-fit: scale-down;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    flex: 2 0 21%;
  }

  .prosjekt-container-tekstboks {
    width: 100%;
  }
}



@media screen and (max-width: 768px) {

  .prosjekt-container:hover {
    border-radius: 7px 7px 0px 0px;
  }

  .innhold-container {
    margin-top: 20px;
  }

  .image {
    width: 150px;
    height: 150px;
    max-height: 150px;
    max-width: 150px;
  }

  .full_container {
    width: 95%;
  }

  .bakgrunn_image {
    padding-top: 150px;
  }

  .prosjekt-container {
    flex-direction: column;
    width: 90%;
  }

  .project-title {
    margin-top: 30px;
  }
}

@media screen and (max-width: 640px) {

  .project-title {
    margin-top: 120px;
    margin-bottom: 10px;
  }

  .prosjekt-container {
    width: 100%;
  }

  .innhold-container {
    margin-top: 30px;
  }

  .prosjekt-container-image {
    background-position: left;
  }

  .linked_in_icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .back_btn_icon {
    height: 40px;
    width: 40px;
  }

  .prosjekt-container-simple {
    width: 100%;
  }

  .full_container {
    width: 100%;
  }

  .bakgrunn_image {
    padding-top: 90px;
  }
}
